<template>
	<div>
		<div class="swiper-container" style="height:700px;">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item, index) of swiperList" :key="index">
					<img :src="item.picture" style="width: 100%;" alt="" mode="widthFix">
				</div>
			</div>
			<!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->
		</div>
		<div class="brand">
			<div>{{ obj.name }}</div>
			<div v-html="obj.content">
			</div>
		</div>
		<div class="box">
			<div class="div divMage" v-for="(item, index) of skipClassifyList" :key="index">
				<!--:style="{bottom:skuipClassifyInde==index ? heights:''}"@mouseover="submenuActive = 1" @mouseleave="submenuActive = 0" :class="skuipClassifyInde==index?'activeview-div':''" -->
				<img :src="item.cover" alt="" mode="widthFix">
				<div class="view-div view-divs">
					<h3>{{ isChinese() ? item.title : item.titleEng }}</h3>
					<div class="intro">
						{{ isChinese() ? item.content : item.contentEng }}
					</div>
					<div class="buttom" @click="$router.push('/productSku?id=' + item.goodsClassifyId)">{{
                        $t('button.chakan') }}</div>
				</div>
			</div>
		</div>

		<div class="product-List">
			<h2>{{ isChinese() ? '推荐商品' : 'Pick of the week' }}</h2>
			<div class="list">
				<productList initialStyle="index" v-for="(item, index) of productList" :product="item" :key="index">
				</productList>
			</div>
		</div>
		<div class="contactUS">
			<h2>{{ $t('footer.lianxi.title') }}</h2>
			<div class="box">
				<div class="left" v-for="(item, index) of contaList" :key="index"
					@click="$router.push({ path: 'contactUs', query: { id: item.id } })">
					<div class="div">
						<img :src="item.cover" alt="">
						<div class="view-div">
							<h3>{{ isChinese() ? item.title : item.titleEng }}</h3>
							<div class="intro">
								{{ isChinese() ? item.intro : item.introEng }}
							</div>
						</div>
					</div>
					<div class="tagA">{{ $t('button.gengduo') }}</div>
				</div>
			</div>
		</div>
		<!-- <div class="JoinUS">
            <h2>{{ $t('footer.jiaru') }}</h2>
            <h3>{{ $t('footer.title') }}</h3>
            <el-input style="width:350px;" :placeholder="$t('footer.emailAdrs')" v-model="email">
                <el-button slot="append" class="elInput" :loading="loading" @click="joinUs">{{ $t('footer.dingyue')
                }}</el-button>
            </el-input>
        </div> -->
	</div>
</template>

<script>
	import "swiper/dist/js/swiper";
	import "swiper/dist/css/swiper.css";
	import Swiper from "swiper";
	import productList from '@/components/productList.vue'
	import {
		listCarousel,
		listGoods,
		listSkipClassify,
		listContactUs,
		getProtocol,
		joinUs
	} from '@/api/main.js'
	export default {
		components: {
			productList
		},
		data() {
			return {
				input2: '',
				swiperList: [],
				productList: [],
				skipClassifyList: [], //获取跳转分类
				contaList: [], //获取联系我们
				obj: {},
				email: '',
				loading: false,
				heights: ''
			}
		},
		mounted() {
			this.isPlatform()
			this.getlistCarousel(); //获取轮播
			this.getlistGoods(); //获取商品
			this.listSkipClassify(); //获取跳转分类
			this.listContactUs() //获取联系我们

			this.getProtocol() //2品牌介绍

		},
		methods: {
			isPlatform() {
				var browser = {
					versions: function() {
						var u = navigator.userAgent;
						return { //移动终端浏览器版本信息
							trident: u.indexOf('Trident') > -1, //IE内核
							presto: u.indexOf('Presto') > -1, //opera内核
							webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
							gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
							mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
							ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
							android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
							iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
							iPad: u.indexOf('iPad') > -1, //是否iPad
							webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
						};
					}(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase()
				}
				if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
					if (window.location.href != 'https://h5.akomapa.online/#/') {
						window.location.href = 'https://h5.akomapa.online/#/'
					}
				} else {
					if (window.location.href != 'https://akomapa.online/#/index') {
						window.location.href = 'https://akomapa.online/#/index'
					}
				}
			},
			initH() {
				console.log(this.$ref['boxs1'])
			},
			getProtocol() {
				getProtocol({
					id: 2 //1公司介绍、2品牌介绍、3购物说明、4隐私权益及网站使用条款、5会员制度
				}).then(res => {
					if (res.code == 0) {
						if (this.isChinese()) {
							this.obj = res.data
						} else {
							this.obj = {
								...res.data,
								name: res.data.nameEng,
								content: res.data.contentEng
							}
						}
					} else {
						this.showMessage();
					}
				})
			},
			listContactUs() { //获取联系我们
				listContactUs({}).then(res => {
					if (res.code == 0) {
						this.contaList = res.data;
					} else {
						this.showMessage();
					}
				})
			},
			listSkipClassify() { //获取跳转分类
				listSkipClassify({}).then(res => {
					if (res.code == 0) {
						this.skipClassifyList = res.data;
					} else {
						this.showMessage();
					}
				})
			},
			getlistCarousel() {
				listCarousel({}).then(res => {
					if (res.code != 0) this.showMessage(res)
					this.swiperList = res.data
					let time = setTimeout(() => {
						clearTimeout(time)
						this.initSwiper()
					}, 0)
				})
			},
			getlistGoods() {
				listGoods({
					tuijianStatus: 1,
					// name:'直',
					pageNum: 1,
					pageSize: 4
				}).then(res => {
					if (res.code == 0) {
						this.productList = res.data.rows
					} else {
						this.showMessage();
					}
				})
			},
			joinUs() {
				var reg = new RegExp("^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$");
				if (!reg.test(this.email)) {
					if (this.isChinese()) {
						this.$message({
							message: '邮箱有误',
							type: 'warning'
						});
					} else {
						this.$message({
							message: 'Incorrect email address',
							type: 'warning'
						});
					}
					return
				}
				joinUs({
					email: this.email
				}).then(res => {
					if (res.code == 0) {
						if (this.isChinese()) {
							this.$message({
								message: '加入成功',
								type: 'success'
							});
						} else {
							this.$message({
								message: 'successfully added',
								type: 'success'
							});
						}
					} else {
						this.showMessage();
					}
				})
			},
			initSwiper() {
				let swiper = new Swiper(".swiper-container", {
					direction: "horizontal", // 垂直切换选项
					//mousewheel: true, //滚轮
					autoplay: {
						//自动开始
						delay: 3500, //时间间隔
						disableOnInteraction: true, //*手动操作轮播图后不会暂停*
					},
					loop: true, // 循环模式选项
					// 如果需要分页器
					pagination: {
						el: ".swiper-pagination",
						clickable: true, // 分页器可以点击
					},

					// 如果需要前进后退按钮
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},

					// 如果需要滚动条
					scrollbar: {
						el: ".swiper-scrollbar",
					},
				});
				swiper.on('click', () => {
					if (this.swiperList[swiper.realIndex].link) {
						window.open(this.swiperList[swiper.realIndex].link);
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.swiper-pagination {
		position: absolute;
		bottom: -0.1rem;
		right: 1rem;
		text-align: right !important;
	}

	.swiper-container ::v-deep .swiper-pagination-bullet-active {
		background-color: #fff;
	}

	.brand {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 100px 150px;

		div:last-child {
			width: 1060px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 30px;
		}

		div:first-child {
			width: 220px;
			font-size: 36px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
		}

	}

	.box {
		display: flex;
		justify-content: center;
		//align-items: center;
		background-color: #fff;
		padding: 30px;

		.left {
			margin-right: 90px;
			margin-left: 90px;

			.tagA {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				text-decoration: underline;
				padding: 20px 50px;
				cursor: pointer;
			}

			.tagA:hover {
				color: #A1A1A1;
			}
		}

		.divMage {
			margin-right: 90px;
			margin-left: 90px;
		}

		.div {
			position: relative;

			img {
				// width: 720px;
				width: 100%;
				height: 100%;
				// height: 660px;
			}

			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.div:hover {
			.view-div {
				bottom: 0px;
				transition: all .5s ease .1s;
			}
		}

		.view-div {
			width: 100%;
			background-color: #fff;
			padding: 30px 50px;
			position: absolute;
			bottom: -78px;
			transition: all .5s ease .1s;
			padding-bottom: 0px;

			//max-height: 220px;
			h3 {
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
			}

			.intro {
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				padding: 30px 0px;
				word-wrap: break-word;
				word-break: break-all;
				height: 145px;
			}

			.buttom {

				width: 168px;
				padding: 8px 0px;
				background: #FFFFFF;
				border: 1px solid #333333;
				font-size: 14px;
				text-align: center;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				cursor: pointer
			}

			.buttom:hover {
				background: #000;
				color: #fff;
				transition: background 1s ease .1s, color 1s ease .1s;
			}

		}

		.view-divs {
			bottom: -180px;
			padding: 30px 50px;
		}
	}

	.product-List {
		background: #F9F9F9;

		h2 {
			padding: 105px 0px;
			text-align: center;
			font-size: 28px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #333333;
			line-height: 40px;
		}

		.list {
			margin: 0 auto;
			//padding: 40px;
			display: flex;
			// justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			width: 85%;
			height: 100%;
		}
	}

	.contactUS {
		padding-bottom: 60px;

		h2 {
			padding: 125px 0px;
			text-align: center;
			font-size: 28px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #333333;
			line-height: 40px;
		}
	}

	.JoinUS {
		text-align: center;
		background: #F9F9F9;
		padding: 98px 0px;

		h3 {
			padding: 32px;
		}

		.elInput {
			width: 130Px;
			height: 40px;
			background: #414042 !important;
			color: #fff;
			border-radius: 0px;
			border: none;
		}

		.elInput:hover {
			background: rgb(36, 36, 36) !important;
			color: #000;
			background-color: transparent !important;
			opacity: 1;
			border: 1px solid #000;
			box-shadow: none;
		}
	}
</style>